function diffence_year(year, month, day) {
  var currentDate = new Date()
  var currentYear = currentDate.getFullYear()
  var currentMonth = currentDate.getMonth()
  var currentDay = currentDate.getDate()
  var diffYear = currentYear - year

  if (currentMonth < month - 1) {
    diffYear--
  }
  if (month - 1 == currentMonth && currentDay < day) {
    diffYear--
  }
  return diffYear
}

function setValue(fieldId, value) {
  var field = document.getElementById(fieldId)
  if (!!field) {
    field.innerHTML = value
  }
}

function setFields() {
  setValue('field_age', diffence_year(1985, 5, 19) + ' ans')
  setValue('field_experience', diffence_year(2009, 4, 1) + ' ans')
}

setTimeout(() => setFields(), 500)
